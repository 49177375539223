import React from 'react';
import {TlfLayout} from '../Common';

export default function UnsubscribePage() {
  return (
    <TlfLayout>
      <br />
      <br />
      <br />
      <center>
        <h2>Successfully unsubscribed!</h2>
      </center>
    </TlfLayout>
  );
}
