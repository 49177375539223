import React from 'react';
import {hydrate} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Router} from './Router';

const mountNode = document.getElementById('mount');
if (!mountNode) throw new Error('Mount node not found.');
hydrate(
  <BrowserRouter>
    <Router />
  </BrowserRouter>,
  mountNode,
);
