import {assocPath, groupWith, map, path, pipe} from 'ramda';
import {isArray} from 'ramda-adjunct';

type MaybeNumberOrString = Maybe<number | string>;

export function noop(): void {}
export function blank(value: unknown): boolean {
  return !notBlank(value);
}
export function notBlank(value: unknown): boolean {
  if (value == null) return false;
  if (value == undefined) return false;
  return !!String(value).trim();
}
export function safeParse(any: unknown, errorValue: unknown = null) {
  try {
    return isString(any) ? JSON.parse(any) : any;
  } catch (e: unknown) {
    return errorValue;
  }
}
export function isPhoneNumber(str: string) {
  return !!(
    str.trim().match(/^[0-9]{10}$/) ||
    str.trim().match(/^[0-9]{3} [0-9]{3} [0-9]{4}$/) ||
    str.trim().match(/^[0-9]{3} [0-9]{3}-[0-9]{4}$/) ||
    str.trim().match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/) ||
    str.trim().match(/^\([0-9]{3}\)\s?[0-9]{3}\s?[0-9]{4}$/) ||
    str.trim().match(/^\([0-9]{3}\)\s?[0-9]{3}-[0-9]{4}$/) ||
    str.trim().match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)
  );
}
export function toPhoneNumber(str: string) {
  const stripped = str.trim().replace(/[^0-9]/g, '');
  return [stripped.slice(0, 3), stripped.slice(3, 6), stripped.slice(6, 10)].join('-');
}

export const formatValueToDollar = (value: number, options?: Intl.NumberFormatOptions): string =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    ...options,
  }).format(value);
// Numbers
export function toFloat(string: MaybeNumberOrString): number {
  return parseFloat(string) || 0;
}
export function rubyNumber(object: Maybe<number>): Maybe<string> {
  if (object === null || object === undefined) return object;
  const number = toFloat(object);
  if (!number && number !== 0) return String(number);
  let result = number.toFixed(10);
  while (result.endsWith('00')) {
    result = result.slice(0, -1);
  }
  if (!result.match(/\.0$/)) {
    result = result.slice(0, -1);
  }
  return result;
}
export function round(number: number, digits: number): number {
  const power = Math.pow(10, digits);
  // This + 0 is necessary to remove the -0 that appears
  // when you round a negative number
  return Math.round(number * power) / power + 0;
}
export function trimTrailingZeros(input: string): string {
  return input.replace(/0+$/g, '');
}
export function numberToCurrency(
  number: (number | string) | null | undefined,
  digits?: number | null | undefined,
): string {
  const parsedNumber = parseFloat(String(number));
  if (isNaN(parsedNumber)) return '';
  const precision = digits || digits === 0 ? digits : 2;
  return parsedNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}
export function numberToPercentage(number: number): string {
  return `${(number * 100).toFixed(0)}%`;
}
// Works on any device
// toLocaleString does not work on Android+ReactNative
export function numberToCurrencyFailSafe(number: (number | string) | null | undefined): string {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) return '';
  return `$${parsedNumber.toFixed(2)}`;
}
export function isNumeric(input: number): boolean {
  return !isNaN(+input);
}
export function numberToEighth(original_size: number | null | undefined): string {
  if (!original_size || original_size == 0) return '';
  const size = Math.ceil(original_size * 8) / 8.0; // round to the next 1/8
  const rem = size - Math.floor(size);
  const integer_part = Math.floor(size) > 0 ? String(Math.floor(size)) : '';
  const decimal_part =
    rem > 0
      ? {
          '1': '1/8',
          '2': '1/4',
          '3': '3/8',
          '4': '1/2',
          '5': '5/8',
          '6': '3/4',
          '7': '7/8',
        }[String(rem * 8)]
      : '';
  const expression = rem > 0 ? 'of a truck' : integer_part == '1' ? 'truck' : 'trucks';
  return [integer_part, decimal_part, expression].filter(x => x).join(' ');
}
// Strings
export function capitalize(string: string | null | undefined): string {
  return (string || '').charAt(0).toUpperCase() + (string || '').slice(1);
}
export function capitalizeEachWord(string: string | null | undefined): string {
  return (string || '').replace(/\w\S*/g, capitalize);
}
export function camelize(text: string): string {
  const [first, ...rest] = text.split('_');
  return [first, ...rest.map(capitalize)].join('');
}
function addSuffix(number: Maybe<string | number>, suffix: string): string {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) return '';
  return [parsedNumber.toFixed(2), suffix].join(' ');
}
export function booleanString(bool: boolean | null | undefined): string {
  return bool ? 'Yes' : 'No';
}
export function pluralize(value: number | string, unit: string, plural?: string | null): string {
  const pluralized = plural || unit + 's';
  return `${value} ${String(value) === '1' ? unit : pluralized}`;
}
export function orArrayLowercase(input: Maybe<OrArray<string>>): Maybe<OrArray<string>> {
  if (!input) return input;
  return isArray(input) ? input.map(x => x?.toLowerCase()) : input.toLowerCase();
}
// Deep merges a into b
export function isObject(a: unknown): a is Record<string, unknown> {
  return typeof a === 'object' && String(a) === '[object Object]';
}
export function isString(a: unknown): a is string {
  return typeof a === 'string';
}
export function isBoolean(a: unknown): a is boolean {
  return typeof a === 'boolean';
}
export function deepMergeInto(a: unknown, b: unknown): unknown {
  return recur(a, b);
  function recur(a: unknown, b: unknown): unknown {
    if (isObject(a) && isObject(b)) {
      const bKeys = Object.keys(b);
      return Object.keys(a).reduce((acc, aKey) => {
        return bKeys.includes(aKey)
          ? {...acc, [aKey]: recur(a[aKey], b[aKey])}
          : {...acc, [aKey]: a[aKey]};
      }, b);
    }
    return a === undefined ? b : a;
  }
}
export function stringValues(object: {[key: string]: string}): string[] {
  return Object.values(object);
}
export function entries<T>(obj: {[key: string]: T}): Array<[string, T]> {
  const keys: string[] = Object.keys(obj);
  return keys.map(key => [key, obj[key]]);
}
// Reducers
export function removeFalsyValues<K extends string | number | symbol, V>(
  object: Record<K, Maybe<V>>,
): Record<K, V> {
  //@ts-ignore
  return Object.entries(object).reduce((acc, [key, value]) => {
    return !value ? acc : {...acc, [key]: value};
  }, {});
}
export function sumByField<K extends string | number | symbol>(
  array: Record<K, number>[],
  attribute: string,
): number {
  return array.map(item => item[attribute]).reduce((a, b) => a + b, 0);
}
export function maybeRubyArray<T, V>(object: T[] | V): string | V {
  if (Array.isArray(object)) return `[${object.map(i => `"${i}"`).join(', ')}]`;
  return object;
}
// MimeTypes
// TODO: use library
export function extensionToMimeType(extension: string): string {
  return (
    {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
    }[extension.toLowerCase()] || 'application/octet-stream'
  );
}
export function base64ToMimeType(base64: string): string {
  return base64.split(',')[0].split(':')[1].split(';')[0];
}
export function formatPhoneUS(originalPhone: string): string | null | undefined {
  return originalPhone.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
}
export function formatPhone(originalPhone: string): string | null | undefined {
  if (!originalPhone) return '';
  const phone = originalPhone.match(/^\+1\d{10}$/) ? originalPhone.slice(2) : originalPhone;
  const strippedPhone = phone.replace(/[^0-9]/g, '');
  return [strippedPhone.slice(0, 3), strippedPhone.slice(3, 6), strippedPhone.slice(6)]
    .filter(Boolean)
    .join('-');
}
export function safeToString(value: unknown): string {
  if (value === null || value === undefined) return '';
  return String(value);
}
// Common Strings
export function renderInches(maybeNumber: MaybeNumberOrString): string {
  return addSuffix(maybeNumber, 'inches');
}
export function renderHours(maybeNumber: Maybe<number>): string {
  return maybeNumber ? `${maybeNumber} h` : '';
}
export function renderPounds(maybeNumber: MaybeNumberOrString): string {
  return addSuffix(maybeNumber, 'pounds');
}
export function renderPercentage(fraction: MaybeNumberOrString): string {
  const parsedNumber = parseFloat(fraction);
  if (isNaN(parsedNumber)) return '';
  return addSuffix((parsedNumber * 100).toFixed(2), '%');
}
export function toPositiveInteger(any: MaybeNumberOrString, defaultNumber: number): number {
  const parsedNumber = parseInt(any);
  return parsedNumber && parsedNumber > 0 ? parsedNumber : defaultNumber;
}
export function removeSingleEntry<T>(list: T[], value: Maybe<T>): T[] {
  const index = list.indexOf(value);
  return [...list.slice(0, index), ...list.slice(index + 1)];
}
export function isJsonSerializable(any: unknown): boolean {
  try {
    JSON.stringify(any);
    return true;
  } catch (_) {
    return false;
  }
}
// Other
export function compactObject(input: Record<string, unknown>): Record<string, unknown> {
  return Object.entries(input).reduce((acc, [k, v]) => (v ? {...acc, [k]: v} : acc), {});
}
export function toNumber(object: MaybeNumberOrString): number {
  return parseFloat(object) || 0;
}
export function toNatural(object: MaybeNumberOrString): number {
  return parseInt(object) || 0;
}
export function undefinedSafe<T1, T2>(
  object: T1 | undefined,
  mapFunction: (x: T1) => T2,
): T2 | undefined {
  return object === undefined ? undefined : mapFunction(object);
}
export function toOrdinal(n: number) {
  const ext = ['th', 'st', 'nd', 'rd'];
  const val = n % 100;
  return String(n) + (ext[(val - 20) % 10] || ext[val] || ext[0]);
}
export function updateWith<T>(lensPath: string | string[], fn, obj: T) {
  const newValue = fn(path([lensPath].flat(), obj));
  return assocPath([lensPath].flat(), newValue, obj);
}
let uniqueIdCounter = 0;
export function uniqueId(prefix?: string) {
  uniqueIdCounter++;
  return `${prefix || ''}${uniqueIdCounter}`;
}
export function splitCamelize(s: string): string[] {
  return pipe(
    () => s.split(''),
    groupWith((_, x) => x === x.toLowerCase()),
    map(x => x.join('')),
  )();
}
export function assert(condition: unknown, message?: string): asserts condition {
  if (!condition) throw new Error(`Invalid assertion${message ? `: ${message}` : ''}.`);
}
