import React, {ReactNode} from 'react';
import {
  ArrowRightGreen,
  CameraIconBlack,
  DrinkIconBlack,
  EnriqueItemPicture,
  EnriquePicture,
  GoogleLogo,
  GreatPricesAndOffers,
  HannahItemPicture,
  HannahPicture,
  IHaveItemsToSellBackground,
  InspectedAndReturns,
  LocalPickupOfDelivery,
  TaniaItemPicture,
  TaniaPicture,
  TlfMiLocation,
  TlfPhLocation,
  TlfSfLocation,
  TruckIconBlack,
  UniquePiecesAndQualityBrands,
  WillItemPicture,
  WillPicture,
  YelpLogo,
} from '../Assets';
import {
  Card,
  Col,
  Image,
  Row,
  TlfCarousel,
  ReperchContainer,
  TlfLayout,
  TLF_URL_BAYAREA,
  TLF_URL_MIAMI,
  TLF_URL_PHOENIX,
} from '../Common';

export default function Home() {
  return (
    <TlfLayout>
      <TlfCarousel />
      <StoreLocations />
      <Reviews />
      <WhyShopWithUs />
      <TlfCopySection />
      <IHaveItemToSell />
    </TlfLayout>
  );
}
function StoreLocations() {
  const style: StyleObject = {
    container: {
      width: '100%',
    },
    row: {
      margin: 0,
    },
    h2: {
      color: '#333333',
      fontWeight: 'bold',
    },
    title: {
      color: '#000000',
      fontWeight: 500,
      fontSize: 32,
      padding: '44px 0 30px',
      textAlign: 'center',
      width: '100%',
    },
    col: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      padding: 0,
      position: 'relative',
      margin: 0,
    },
    location: {
      fontWeight: 500,
      textAlign: 'center',
    },
    sf: {
      backgroundImage: `url(${TlfSfLocation})`,
    },
    ph: {
      backgroundImage: `url(${TlfPhLocation})`,
    },
    mi: {
      backgroundImage: `url(${TlfMiLocation})`,
    },
    goToShop: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      marginTop: 20,
      padding: '8px 40px',
    },
  };
  return (
    <div id='store-locations-section'>
      <div className='title' style={style.title}>
        View Collections To Buy In
      </div>
      <ReperchContainer style={style.container}>
        <Row style={style.row}>
          <Col xs={12} sm={4} style={{...style.col, ...style.sf}}>
            <div style={style.location}>
              San Francisco
              <br />
              Bay Area
            </div>
            <a href={TLF_URL_BAYAREA} target='_blank' style={style.goToShop} rel='noreferrer'>
              Shop Now
            </a>
          </Col>
          <Col xs={12} sm={4} style={{...style.col, ...style.ph}}>
            <div style={style.location}>Phoenix</div>
            <a href={TLF_URL_PHOENIX} target='_blank' style={style.goToShop} rel='noreferrer'>
              Shop Now
            </a>
          </Col>
          <Col xs={12} sm={4} style={{...style.col, ...style.mi}}>
            <div style={style.location}>Miami</div>
            <a href={TLF_URL_MIAMI} target='_blank' rel='noreferrer' style={style.goToShop}>
              Shop Now
            </a>
          </Col>
        </Row>
      </ReperchContainer>
    </div>
  );
}
function WhyShopWithUs() {
  const style: StyleObject = {
    section: {
      backgroundColor: '#278C8B',
      color: '#FFFFFF',
      padding: '105px 0',
    },
    container: {
      color: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      maxWidth: 768,
    },
    text: {
      textAlign: 'center',
    },
  };
  return (
    <div id='why-thelocalflea-section' style={style.section}>
      <ReperchContainer style={style.container}>
        <div
          style={{
            ...style.text,
            fontWeight: 500,
            fontSize: 32,
            marginBottom: 30,
          }}
        >
          Why Shop With Us
        </div>
        <div
          style={{
            ...style.text,
            fontSize: 20,
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          We aim to make the process of buying pre-owned as easy as buying new!
        </div>
        <div
          style={{
            ...style.text,
            fontSize: 20,
          }}
        >
          We have the largest collection of quality pre-loved items so that you can find anything to
          fit your style and budget. Every time you buy from us you help us on our mission to reduce
          waste by increasing reuse!
        </div>
      </ReperchContainer>
    </div>
  );
}
function TlfCopySection() {
  return (
    <div id='tlf-copy-section' style={{backgroundColor: '#FFFFFF'}}>
      <ReperchContainer style={{width: '100%'}}>
        <CopyRow
          photoSrc={UniquePiecesAndQualityBrands}
          headline='Unique pieces and quality brands'
        >
          All our pieces are carefully curated before we accept them on consignment. We receive a
          full assortment of items from quality known brands to unique pieces and styles.
        </CopyRow>
        <CopyRow inverted photoSrc={GreatPricesAndOffers} headline='Great prices and offers'>
          By buying pre-owned you not only help quality items find a new home but you are also able
          to purchase quality products at a steep discount to retail. We even accept offers!
        </CopyRow>
        <CopyRow photoSrc={LocalPickupOfDelivery} headline='Local pickup or delivery'>
          Convenience is our goal! All purchases can be picked up at our local showrooms. We also
          offer flat-fee delivery within the area and national shipping.
        </CopyRow>
        <CopyRow inverted photoSrc={InspectedAndReturns} headline='Inspected and returns'>
          Every item has been inspected and we provide as much information as we have available. Al
          blemishes are photographed so that you have no surprises when you receive the item.
          Unhappy with your purchase? We also offer 7-day returns!
        </CopyRow>
      </ReperchContainer>
    </div>
  );
  function CopyRow({
    inverted,
    photoSrc,
    headline,
    children,
  }: {
    inverted?: boolean;
    photoSrc: string;
    headline: string;
    children: ReactNode;
  }) {
    const className = ['tlf-copy-row', inverted ? 'inverted' : ''].join(' ');
    return (
      <div
        className={className}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image className='tlf-copy-column' src={photoSrc} responsive />
        <div className='tlf-copy-column'>
          <div className='tlf-copy-column-headline' style={{color: '#000000'}}>
            {headline}
          </div>
          <div className='tlf-copy-column-text' style={{color: '#000000'}}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
type ReviewsState = {
  reviewCarouselIndex: number;
};
class Reviews extends React.PureComponent<unknown, ReviewsState> {
  interval: number;
  constructor(props: unknown) {
    super(props);
    this.state = {
      reviewCarouselIndex: 0,
    };
  }
  componentDidMount() {
    this.interval = window.setInterval(
      () =>
        this.setState(state => ({
          reviewCarouselIndex: (state.reviewCarouselIndex + 1) % 4,
        })),
      5000,
    );
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  goLeft = () => {
    this.setState(({reviewCarouselIndex}) => ({
      reviewCarouselIndex: reviewCarouselIndex === 0 ? 3 : (reviewCarouselIndex - 1) % 4,
    }));
  };
  goRight = () => {
    this.setState(({reviewCarouselIndex}) => ({
      reviewCarouselIndex: (reviewCarouselIndex + 1) % 4,
    }));
  };
  render() {
    const {reviewCarouselIndex} = this.state;
    return (
      <ReperchContainer id='tlf-reviews-section' style={{textAlign: 'center'}}>
        <ReviewCarousel index={reviewCarouselIndex} goLeft={this.goLeft} goRight={this.goRight} />
      </ReperchContainer>
    );
  }
}

function ReviewCarousel({
  index,
  goRight,
  goLeft,
}: {
  index: number;
  goRight: GenericFunction;
  goLeft: GenericFunction;
}) {
  const hannahText =
    '“Super successful trip to TheLocalFlea today. I had gone to several stores for a couch. ' +
    'I was discouraged because they were all so expensive!  I found one at the Flea and ' +
    "it's beautiful and in great condition.  Super nice staff too!”";
  const willText =
    '“Great place to find good quality to high end goods @ fair prices. They Make buying easy ' +
    'and are willing to accept offers on many items. They carefully pack and protect your item.”';
  const taniaText =
    '“This place is a hidden gem!!! I came here to look at a dining table that I saw on their ' +
    'website, and I came out of it with a beautiful dining table, six dining chairs, an end ' +
    'table, a floor lamp, and a painting.”';
  const enriqueText =
    '“Awesome place ..I am so pleased with the piece I bought... delivery was great I will but ' +
    "again. It's like the amazon of cool furniture.”";
  const reviews = [
    {
      name: 'Hannah',
      from: 'San Mateo, CA',
      text: hannahText,
      src: HannahPicture,
      itemSrc: HannahItemPicture,
    },
    {
      name: 'Will',
      from: 'San Francisco, CA',
      text: willText,
      src: WillPicture,
      itemSrc: WillItemPicture,
    },
    {
      name: 'Tania',
      from: 'Scottsdale, AZ',
      text: taniaText,
      src: TaniaPicture,
      itemSrc: TaniaItemPicture,
    },
    {
      name: 'Enrique',
      from: 'Tempe, AZ',
      text: enriqueText,
      src: EnriquePicture,
      itemSrc: EnriqueItemPicture,
    },
  ];
  const marginLeft = `-${(index % reviews.length) * 100}%`;
  return (
    <div
      style={{
        width: '100%',
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <div style={{overflow: 'hidden'}}>
        <Row className='carousel-wrapper' style={{marginLeft}}>
          {reviews.map(review => (
            <Review
              key={review.name}
              name={review.name}
              from={review.from}
              message={review.text}
              src={review.src}
              itemSrc={review.itemSrc}
            />
          ))}
        </Row>
      </div>
      <div
        onClick={goLeft}
        style={{
          position: 'absolute',
          cursor: 'pointer',
          left: 0,
          top: '40%',
          backgroundColor: 'transparent',
          borderRadius: '25px',
          textAlign: 'left',
        }}
      >
        <img
          className='arrow'
          style={{
            transform: 'rotate(180deg)',
            WebkitTransform: 'rotate(180deg)',
          }}
          src={ArrowRightGreen}
        />
      </div>
      <div
        onClick={goRight}
        style={{
          position: 'absolute',
          cursor: 'pointer',
          right: 0,
          top: '40%',
          backgroundColor: 'transparent',
          borderRadius: '25px',
          textAlign: 'right',
        }}
      >
        <img className='arrow' src={ArrowRightGreen} />
      </div>
    </div>
  );
}
function Review({
  message,
  name,
  from,
  src,
  itemSrc,
}: {
  message: string;
  name: string;
  from: string;
  src: string;
  itemSrc: string;
}) {
  const style: StyleObject = {
    reviewContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    review: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
    reviewerPhoto: {
      height: 78,
      width: 'auto',
    },
    reviewerName: {
      color: '#000000',
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 38,
    },
    reviewerMessage: {
      color: '#000000',
      textAlign: 'center',
    },
    reviewItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    yelpGoogleLogo: {
      height: 42,
      width: 'auto',
    },
  };
  return (
    <Col xs={3} style={{height: '100%'}}>
      <div className='review-container' style={style.reviewContainer}>
        <div className='review' style={style.review}>
          <Image className='reviewer-photo' style={style.reviewerPhoto} src={src} responsive />
          <div style={style.reviewerName}>
            {name}, {from}
          </div>
          <div className='reviewer-message' style={style.reviewerMessage}>
            {message}
          </div>
        </div>
        <div className='item' style={style.reviewItem}>
          <Image src={itemSrc} responsive />
        </div>
        <div className='yelp-and-google'>
          <Image src={YelpLogo} style={{...style.yelpGoogleLogo, marginRight: 30}} />
          <Image src={GoogleLogo} style={style.yelpGoogleLogo} />
        </div>
      </div>
    </Col>
  );
}
function IHaveItemToSell() {
  const style: StyleObject = {
    container: {
      width: '100%',
    },
    headline: {
      textTransform: 'capitalize',
    },
    cardsContainer: {
      backgroundImage: `url(${IHaveItemsToSellBackground})`,
      backgroundRepeat: 'no-repeat',
      width: '100%',
    },
    cards: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-evenly',
      alignItems: 'stretch',
      marginLeft: 'auto',
      maxWidth: 930,
    },
    card: {
      justifyContent: 'flex-start',
    },
    goToRemoov: {
      backgroundColor: '#278C8B',
      color: '#FFFFFF',
      fontSize: 20,
      fontWeight: 500,
      padding: '16px 100px',
    },
  };
  return (
    <div id='tlf-i-have-items-to-sell-section'>
      <ReperchContainer style={style.container}>
        <div className='headline' style={style.headline}>
          I have items to sell
        </div>
        <div className='subheadline'>
          Our parent company Remoov collect’s everything that you want to sell, donate or dispose
        </div>
        <div className='go-to-remoov'>
          <a
            href={'https://remoovit.com'}
            target='_blank'
            style={style.goToRemoov}
            rel='noreferrer'
          >
            Learn More
          </a>
        </div>
        <div style={style.cardsContainer} className='cards-container'>
          <div style={style.cards} className='cards-list'>
            <Card icon={CameraIconBlack} title='Submit photos' style={style.card}>
              Text photos to 415-969-4060 or schedule an in-home appointment
            </Card>
            <Card icon={TruckIconBlack} title='Schedule a pickup' style={style.card}>
              We bring our truck and collect everything
            </Card>
            <Card icon={DrinkIconBlack} title='Relax' style={style.card}>
              We handle everything else and update you when your items are sold
            </Card>
          </div>
        </div>
      </ReperchContainer>
    </div>
  );
}
