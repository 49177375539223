export {default as ArrowRight} from './arrow_pointing_right.svg';
export {default as ArrowRightGreen} from './arrow_right_green.svg';
export {default as ArrowRightWhite} from './arrow_right_white.svg';
export {default as CameraIcon} from './camera_icon.svg';
export {default as CameraIconBlack} from './camera_icon_black.svg';
export {default as DrinkIcon} from './drink_icon.svg';
export {default as DrinkIconBlack} from './drink_icon_black.svg';
export {default as EnriqueItemPicture} from './enrique_item_picture.png';
export {default as EnriquePicture} from './enrique_picture.png';
export {default as FacebookIcon} from './facebook.svg';
export {default as GoogleLogo} from './google_logo.png';
export {default as GreatPricesAndOffers} from './great_prices_and_offers.png';
export {default as HannahItemPicture} from './hannah_item_picture.png';
export {default as HannahPicture} from './hannah_picture.png';
export {default as InspectedAndReturns} from './inspected_and_returns.png';
export {default as InspectIcon} from './inspect_icon.svg';
export {default as InstagramIcon} from './instagram.svg';
export {default as IHaveItemsToSellBackground} from './i_have_items_to_sell_background.png';
export {default as LocalPickupOfDelivery} from './local_pickup_or_delivery.png';
export {default as LowPriceIcon} from './low_price_icon.svg';
export {default as TlfPattern} from './pattern.jpg';
export {default as PinterestIcon} from './pinterest.svg';
export {default as SellYourItemsBackground} from './sell_your_items.png';
export {default as SofaIcon} from './sofa_icon.svg';
export {default as TaniaItemPicture} from './tania_item_picture.png';
export {default as TaniaPicture} from './tania_picture.png';
export {default as TlfFavicon} from './TlfFavicon.ico';
export {default as TlfLogoOld} from './TlfLogoOld.png';
export {default as TlfOpenGraphImage} from './TlfOpenGraphImage.jpg';
export {default as TlfBayarea} from './tlf_bayarea.jpg';
export {default as TlfHeaderBackground1} from './tlf_header_background_1.png';
export {default as TlfHeaderBackground2} from './tlf_header_background_2.png';
export {default as TlfHeaderBackground3} from './tlf_header_background_3.png';
export {default as TlfHeaderBackground4} from './tlf_header_background_4.png';
export {default as TlfLogo} from './tlf_logo.png';
export {default as TlfLogoGreen} from './tlf_logo_green.png';
export {default as TlfMiLocation} from './tlf_mi_location.jpg';
export {default as TlfPhLocation} from './tlf_ph_location.png';
export {default as TlfSfLocation} from './tlf_sf_location.png';
export {default as TruckIcon} from './truck_icon.svg';
export {default as TruckIcon2} from './truck_icon_2.svg';
export {default as TruckIconBlack} from './truck_icon_black.svg';
export {default as TwitterIcon} from './twitter.svg';
export {default as UniquePiecesAndQualityBrands} from './unique_pieces_and_quality_brands.png';
export {default as WillItemPicture} from './will_item_picture.png';
export {default as WillPicture} from './will_picture.png';
export {default as YelpLogo} from './yelp_logo.svg';

// TODO: not used, delete?
// export {default as TlfPhoenix} from './tlf_phoenix.jpg';
// export {default as TlfBayareaFull} from './tlf_bayarea_full.jpg';
// export {default as TlfPhoenixFull} from './tlf_phoenix_full.jpg';
