import {capitalizeEachWord, nodeApiEndpoint, Offer, splitCamelize} from '@remoov/functional';
import React, {useEffect, useState} from 'react';
import {TlfLayout} from '../Common';

type State = {offer: null | Offer; error: null | string};
export default function OfferPage() {
  const [state, setState] = useState<State>({offer: null, error: null});
  useEffect(() => {
    fetchOffer();

    async function fetchOffer() {
      try {
        const response = await fetch(nodeApiEndpoint(), {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            payload: {
              execute: 'loadOfferByEncryptedId',
              params: window.location.pathname.split('/').pop(),
            },
          }),
        });
        if (response.status !== 200) throw new Error('Offer not found.');
        const offer = await response.json();
        setState({offer, error: null});
      } catch (error: unknown) {
        setState({offer: null, error: String(error)});
      }
    }
  }, []);
  const {offer, error} = state;
  return (
    <TlfLayout>
      <br />
      <br />
      <br />
      {!offer && !error && <h2 style={{marginBottom: 200}}>Loading...</h2>}
      {offer && !error && <h2>Offer {offerToHumanStatus(offer)}</h2>}
      {error && <h2>{error}</h2>}
    </TlfLayout>
  );
}
function offerToHumanStatus(offer: Offer): string {
  return capitalizeEachWord(splitCamelize(offer.status).join(' '));
}
